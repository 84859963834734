<template>
	<div class="page">
		<div class="relative bg-white content s1 flex mt-4">
			<van-search v-model="shopManagerName"
			            placeholder="搜索"
			            shape="round"
			            class="flex-1"
			            @update:model-value="debounceData"/>
			<van-button type="primary"
			            round
			            size="small"
			            class="mr-3"
			            style="padding: 4px 13px"
			            @click="getData">搜索
			</van-button>
			<van-button type="primary"
			            plain
			            round
			            size="small"
			            class="mr-3"
			            style="padding: 4px 13px"
			            @click="syncShop">同步
			</van-button>
		</div>
		<div class="divider s1"></div>
		<van-list v-model:loading="loading"
		          :finished="finished"
		          finished-text=""
		          @load="getData">
			<div class="s1 p-card">
				<no-data v-if="list.length===0"
				         title="暂无店长数据，请同步"
				         :show-button="false"/>
				<div v-for="(item,index) in list"
				     :key="item.robotWxId"
				     class="item flex"
				     @click="changeTeacher(index)">
					<input v-model="item.isChecked"
					       type="checkbox"
					       class="radio mr-1">
					<div class="avatar"><img :src="item.shopManagerLogoUrl"></div>
					<div class="flex-1">
						<p class="p1">{{ item.shopManagerName }}</p>
					</div>
				</div>
			</div>
		</van-list>
		<div class="tips">选择指定的店长后，素材海报中将显示店长的店铺二维码
		</div>
		<div class="p-footer h-end">
			<button class="btn auto "
			        @click="$router.go(-1)">取消
			</button>
			<button class="btn fill ml-3 "
			        @click="handleSave">确定
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Toast } from 'vant'
import { ApiBindShopManager, ApiGetShopManagerByChatroom, ApiSyncShopManager } from '@/apis/User'
import { debounce, throttle } from '@/utils'

export default {
	name: 'shopManager',
	data() {
		return {
			list: [],
			chatroomList: [],
			chatroomId: '',
			pageIndex: 1,
			pageSize: 15,
			loading: false,
			finished: false,
			shopManagerName: '',
			choseIndex: '',
			type: false,

			debounceData: null,
			throttleAsyncGroup: null
		}
	},
	computed: {
		...mapGetters(['globalData', 'robotInfo'])
	},
	created() {
		this.chatroomId = this.$route.query.chatroomId
		this.debounceData = debounce(this.getData, 1000)
		this.throttleAsyncGroup = throttle(this.syncShop, 5000)
	},
	methods: {
		async getData() {
			this.loading = true
			try {
				const data = {
					chatroomId: this.chatroomId,
					robotWxId: this.robotInfo.robotWxId,
					shopManagerName: this.shopManagerName
				}
				this.list = await ApiGetShopManagerByChatroom({ ...data })
				this.loading = false
				this.finished = true
			} catch (e) {
				this.loading = false
				this.finished = true
			}
		},

		async syncShop() {
			await ApiSyncShopManager()
			this.$Toast('同步成功,请点击刷新按钮进行刷新！')
			await this.getData()
		},

		changeTeacher(index) {
			const _this = this
			_this.list.map(item => {
				item.isChecked = false
			})
			_this.list[index].isChecked = !_this.list[index].isChecked
		},

		async handleSave() {
			const toast1 = Toast.loading({
				message: '设置中...',
				forbidClick: true,
				loadingType: 'spinner'
			})
			let id = ''
			this.list.some(item => {
				if (item.isChecked) {
					id = item.shopManagerId
				}
				return item.isChecked
			})
			if (!id) {
				this.$Toast('请先选择某一项！')
				return
			}
			let param = {
				robotWxId: this.robotInfo.robotWxId,
				chatroomId: this.chatroomId,
				shopManagerId: id
			}
			await ApiBindShopManager( {...param})
			toast1.clear()
			this.$router.go(-1)

		},
		async submit(n) {
			const _this = this
			if (n) {
				const toast1 = Toast.loading({
					message: '设置中...',
					forbidClick: true,
					loadingType: 'spinner'
				})
				let way = _this.type ? '/User/BindShopManager' : '/FollowLive/SetTeacher'

				let id = ''
				_this.list.map(item => {
					if (_this.type) {
						_this.list.map(item => {
							if (item.isChecked) {
								id = item.shopManagerId
							}
						})
					} else {
						_this.list.map(item => {
							if (item.isTeacher) {
								id = item.memberWxId
							}
						})
					}
				})
				if (!id) {
					_this.$Toast('请先选择某一项！')
					return
				}
				let param = {
					robotWxId: _this.robotInfo.robotWxId,
					chatroomId: _this.chatroomId,
					teacherWxId: id,
					shopManagerId: id
				}
				await this.$post(way, param)
				          .then(res => {
					          _this.$Toast('保存成功')
					          // _this.getData();
				          })
				toast1.clear()
				if (_this.$route.query.type) {
					this.$router.go(-1)
				} else {
					this.$router.replace('/gather')
				}

			} else {
				this.$router.go(-1)
			}
		}
	}
}
</script>

<style scoped lang="scss">
.tips {
	padding: px(15);
	display: flex;
	align-items: center;
	font-size: px(12);
	line-height: px(16);
	color: $color-gray;

	&:before {
		content: "";
		display: block;
		margin-right: px(5);
		width: px(16);
		height: px(16);
		background: url("assets/icon-1.png") no-repeat;
		background-size: contain;
	}
}

@import "index";
</style>
