import { myAxios } from '@/utils/request'

// 根据群获取店长信息
const ApiGetShopManagerByChatroom = (data = {}) => {
  return myAxios.post('/User/GetShopManagerByChatroom', {
    // 机器人编号
    robotWxId: data.robotWxId,
    // 群编号
    chatroomId: data.chatroomId,
    // 店长名称
    shopManagerName: data.shopManagerName
  })
}
// 同步店长
const ApiSyncShopManager = (robotWxId) => {
  return myAxios.get('/User/SyncShopManager')
}


// 绑定店长信息
const ApiBindShopManager = (data = {}) => {
  return myAxios.post('/User/BindShopManager', {
    // 机器人编号
    robotWxId: data.robotWxId,
    // 群编号
    chatroomId: data.chatroomId,
    // 店长名称
    shopManagerId: data.shopManagerId
  })
}
export  {
  // 根据群获取店长信息
  ApiGetShopManagerByChatroom,
  // 同步店长
  ApiSyncShopManager,
  // 绑定店长信息
  ApiBindShopManager
}
